import React, { useState, useEffect } from 'react';
import styles from './ConoFuturo.module.css';
import FlechaFebInverse from './Flechas/FlechaFebInversa'
import FlechaFeb from './Flechas/FlechaFeb'
import PuntaFlecha from './Flechas/PuntaFlecha';
import Flecha from './Flechas/FlechaPrincipal'


const Nodo = ({ text, type, style }) => {

    const MAX_CHARACTERS = 60;

    const truncateText = (text) => {
        if (!text) return '\u00A0';

        const limit = MAX_CHARACTERS - 3;
        if (text.length <= limit) {
            return text.trim();
        }

        const truncated = text.slice(0, limit);
        const lastSpace = truncated.lastIndexOf(' ');

        if (lastSpace === -1) {
            return truncated + '...';
        }

        return truncated.slice(0, lastSpace).trim() + '...';
    };

    return (
        <div className={`${styles.nodo} ${styles[type]}`} style={{ ...style }}>
            {truncateText(text)}
        </div>

    );
};

const TextoEscenario = ({ text, color, style }) => {
    return (
        <div className={styles.textoEscenario} style={{ ...style }}>
            <p className={styles.subtextoEscenario} style={{ color: color }}>
                {text}
            </p>
        </div>
    );
};

const ConoFuturoImage = ({ data, mobile }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showScrollHint, setShowScrollHint] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

    console.log("Data: ", data)

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        const timeoutFade = setTimeout(() => {
            setFadeOut(true); // Inicia la transición de opacidad
        }, 2000); // Espera 2 segundos antes de empezar a desvanecer

        const timeoutHide = setTimeout(() => {
            setShowScrollHint(false); // Oculta el elemento completamente
        }, 5000); // Se esconde después de la animación

        window.addEventListener('resize', handleResize);
        return () => {
            clearTimeout(timeoutFade);
            clearTimeout(timeoutHide);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.app}>
            <header className={styles.header}>
                <div className={styles.actions}>
                    <h1 className={styles.title}>Tus escenarios futuros</h1>
                    <FlechaFeb />
                    <FlechaFebInverse />
                    <FlechaFeb />
                </div>
            </header>
            {showScrollHint && isMobile && (
                <div className={styles.scrollHintContainer}>
                    <div className={styles.corner + " " + styles.cornerTopLeft}></div>

                    <div className={styles.corner + " " + styles.cornerBottomRight}></div>

                    <div className={styles.scrollHintTop}>
                        ↔ Navega de lado a lado dentro de esta sección
                    </div>

                    <div className={styles.scrollHintBottom}>
                        ↔ Navega de lado a lado dentro de esta sección
                    </div>
                </div>
            )}
            <main className={styles.container}>
                <div id="conoFuturo" className={styles.cono}>
                    <div className={styles.overlay} style={{ overflow: 'hidden' }}>

                        <Nodo text={data.Puesto || ''} type="puesto" style={{ left: '7%', top: '46.8%' }} />
                        <Flecha
                            style={{ left: '-8.4%', top: '25.9%', transform: 'rotate(70deg)' }}
                            length={590}
                            curved dotted
                        />
                        <PuntaFlecha style={{ top: "5.8%", left: "6.7%", transform: "rotate(29deg)" }} />
                        <TextoEscenario
                            text="Escenarios altamente divergentes"
                            color="#E592BA"
                            style={{
                                top: "35%",
                                left: "-2%",
                            }}
                        />

                        <TextoEscenario
                            text="Escenarios adyacentes I"
                            color="#A4CBF4"
                            style={{
                                top: "32%",
                                left: "46%",
                            }}
                        />

                        <TextoEscenario
                            text="Escenarios ambiciosos"
                            color="#EFCE2A"
                            style={{
                                top: "15%",
                                left: "23.6%",
                            }}
                        />

                        <TextoEscenario
                            text="Escenarios directos"
                            color="#64CD84"
                            style={{
                                top: "53%",
                                left: "44%",
                            }}
                        />

                        <TextoEscenario
                            text="Escenarios ambiciosos"
                            color="#EFCE2A"
                            style={{
                                top: "53%",
                                left: "84%",
                            }}
                        />

                        <Flecha style={{ left: '10%', top: '47%' }} length={764} />
                        <PuntaFlecha style={{ top: "47.6%", left: "71.8%", transform: "rotate(270deg)" }} />



                        <Flecha style={{ left: '9.6%', top: '26.8%', transform: 'rotate(134deg)' }} length={778} />
                        <PuntaFlecha style={{ top: "6.2%", left: "63.46%", transform: "rotate(219deg)" }} />
                        <TextoEscenario
                            text="Escenarios adyacentes II"
                            color="#A4CBF4"
                            style={{
                                top: "65%",
                                left: "16%"
                            }}
                        />
                        <TextoEscenario
                            text="Escenarios ambiciosos"
                            color="#EFCE2A"
                            style={{
                                top: "80%",
                                left: "55%",
                            }}
                        />


                        <Flecha style={{ left: '14%', top: '65.9%', transform: 'rotate(31deg)' }} length={998} />
                        <PuntaFlecha style={{ top: "85.3%", left: "89.1%", transform: "rotate(185deg)" }} />

                        <Flecha style={{ left: '80%', top: '47%' }} length={245} />
                        <PuntaFlecha style={{ top: "47.65%", left: "99.2%", transform: "rotate(270deg)" }} />


                        {data.EscenariosAltamenteDivergentes?.[1]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[1] || ''} type="divergente" style={{ left: '14%', top: '42%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[2]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[2] || ''} type="divergente" style={{ left: '13%', top: '37%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[3]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[3] || '' || ''} type="divergente" style={{ left: '12%', top: '32%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[4]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[4] || ''} type="divergente" style={{ left: '11%', top: '27%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[5]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[5] || ''} type="divergente" style={{ left: '10%', top: '22%' }} />}
                        {data.EscenariosAltamenteDivergentes?.["Ambicioso 1"]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.["Ambicioso 1"] || ''} type="ambicioso" style={{ left: '9%', top: '17%' }} />}
                        {data.EscenariosAltamenteDivergentes?.["Ambicioso 2"]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.["Ambicioso 2"] || ''} type="ambicioso" style={{ left: '8%', top: '12%' }} />}
                        {data.EscenariosAltamenteDivergentes?.["Ambicioso 3"]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.["Ambicioso 3"] || ''} type="ambicioso" style={{ left: '7%', top: '7%' }} />}

                        {data.EscenariosDirectos?.[1]?.trim() && <Nodo text={data.EscenariosDirectos?.[1] || ''} type="directo" style={{ left: '25%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[2]?.trim() && <Nodo text={data.EscenariosDirectos?.[2] || ''} type="directo" style={{ left: '34.5%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[3]?.trim() && <Nodo text={data.EscenariosDirectos?.[3] || ''} type="directo" style={{ left: '43.9%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[4]?.trim() && <Nodo text={data.EscenariosDirectos?.[4] || ''} type="directo" style={{ left: '53.2%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[5]?.trim() && <Nodo text={data.EscenariosDirectos?.[5] || ''} type="directo" style={{ left: '62.6%', top: '46%' }} />}
                        {data.EscenariosDirectos?.["Ambicioso 1"]?.trim() && <Nodo text={data.EscenariosDirectos?.["Ambicioso 1"] || ''} type="ambicioso" style={{ left: '72.6%', top: '46%' }} />}
                        {data.EscenariosDirectos?.["Ambicioso 2"]?.trim() && <Nodo text={data.EscenariosDirectos?.["Ambicioso 2"] || ''} type="ambicioso" style={{ left: '81.6%', top: '46%' }} />}
                        {data.EscenariosDirectos?.["Ambicioso 3"]?.trim() && <Nodo text={data.EscenariosDirectos?.["Ambicioso 3"] || ''} type="ambicioso" style={{ left: '90.6%', top: '46%' }} />}


                        {data.EscenariosAdyacentes?.[1]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[1] || ''} type="adyacente" style={{ left: '23.5%', top: '41%' }} />}
                        {data.EscenariosAdyacentes?.[2]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[2] || ''} type="adyacente" style={{ left: '28%', top: '36%' }} />}
                        {data.EscenariosAdyacentes?.[3]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[3] || ''} type="adyacente" style={{ left: '32%', top: '31%' }} />}
                        {data.EscenariosAdyacentes?.[4]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[4] || ''} type="adyacente" style={{ left: '36%', top: '26%' }} />}
                        {data.EscenariosAdyacentes?.[5]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[5] || ''} type="adyacente" style={{ left: '41%', top: '21%' }} />}
                        {data.EscenariosAdyacentes?.["Ambicioso 1"]?.trim() && <Nodo text={data.EscenariosAdyacentes?.["Ambicioso 1"] || ''} type="ambicioso" style={{ left: '46%', top: '16%' }} />}
                        {data.EscenariosAdyacentes?.["Ambicioso 2"]?.trim() && <Nodo text={data.EscenariosAdyacentes?.["Ambicioso 2"] || ''} type="ambicioso" style={{ left: '51%', top: '11.5%' }} />}
                        {data.EscenariosAdyacentes?.["Ambicioso 3"]?.trim() && <Nodo text={data.EscenariosAdyacentes?.["Ambicioso 3"] || ''} type="ambicioso" style={{ left: '56%', top: '7.8%' }} />}


                        {data.EscenariosAdyacentesII?.[1]?.trim() && <Nodo text={data.EscenariosAdyacentesII?.[1] || ''} type="adyacenteii" style={{ left: '25%', top: '51%' }} />}
                        {data.EscenariosAdyacentesII?.[2]?.trim() && <Nodo text={data.EscenariosAdyacentesII?.[2] || ''} type="adyacenteii" style={{ left: '33%', top: '55.5%' }} />}
                        {data.EscenariosAdyacentesII?.[3]?.trim() && <Nodo text={data.EscenariosAdyacentesII?.[3] || ''} type="adyacenteii" style={{ left: '41%', top: '60%' }} />}
                        {data.EscenariosAdyacentesII?.[4]?.trim() && <Nodo text={data.EscenariosAdyacentesII?.[4] || ''} type="adyacenteii" style={{ left: '49%', top: '64.5%' }} />}
                        {data.EscenariosAdyacentesII?.[5]?.trim() && <Nodo text={data.EscenariosAdyacentesII?.[5] || ''} type="adyacenteii" style={{ left: '57%', top: '69%' }} />}
                        {data.EscenariosAdyacentesII?.["Ambicioso 1"]?.trim() && <Nodo text={data.EscenariosAdyacentesII?.["Ambicioso 1"] || ''} type="ambicioso" style={{ left: '65%', top: '73.5%' }} />}
                        {data.EscenariosAdyacentesII?.["Ambicioso 2"]?.trim() && <Nodo text={data.EscenariosAdyacentesII?.["Ambicioso 2"] || ''} type="ambicioso" style={{ left: '73%', top: '78%' }} />}
                        {data.EscenariosAdyacentesII?.["Ambicioso 3"]?.trim() && <Nodo text={data.EscenariosAdyacentesII?.["Ambicioso 3"] || ''} type="ambicioso" style={{ left: '81%', top: '82.5%' }} />}


                    </div>
                </div>
            </main>
        </div>
    );
};

export default ConoFuturoImage;
